/* PHONE */
@media only screen and (max-width: 992px) {
  .container {
    position: absolute;
    bottom: 0;
    right: 0;
    left: 0;
    width: 100%;
    border-top: 1px solid #ccc;
    background-color: white;
  }
}


/* DESKTOP */
@media only screen and (min-width: 992px) {
  .container {
    display: none;
  }
}

.container ul {
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 0;
  margin: 0;
  list-style: none;
  background-color: white;
}

.container li {
  flex: 1;
  text-align: center;
}

.container li:last-child {
  border-right: none;
}

.container a {
  display: block;
  padding: 16px;
  text-decoration: none;
  color: #333;
  font-weight: 600;
}