

.feed {
    width: 100%;
    height: 100vh;
    border: 1px #f1f1f1 solid;
}

.feed .header {
    width: 40.44%;
    position: fixed;
    background-color: white;
    display: flex;
    justify-content: space-between;
    padding-top: 10px;
    margin-bottom: 10px;
    /* border: 1px #f1f1f1 solid; */
}

.feed .header .tab-head {
    display: flex;
    justify-content: center;
    width: 33%;
}

.feed .header .tab-head h6 {
    font-size: 14px;
    color: #474747;
}