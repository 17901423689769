.container {
    height: 77vh;
    overflow-y: auto;
    background-color: white;
}

.container::-webkit-scrollbar {
    width: 10px;
}

.container::-webkit-scrollbar-track {
    background: #f1f1f1;
}

.container::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 10px;
}

.container::-webkit-scrollbar-thumb:hover {
    background: #808080;
}

.searchContainer {
    padding-top: 10px;
}

.searchInput {
    width: 100%;
    margin-left: auto;
    margin-right: auto;
}