.search {
    height: 100vh;
    background-color: white
}

.search .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 50px;
    background-color: white;
    padding-top: 10px;
    /* border-bottom: 1px #f1f1f1 solid; */
}

.search .header .button-back {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 40px;
    height: 40px;
    background-color: white;
    border-radius: 20px;
    cursor: pointer;
}

.search .header .button-back:hover {
    background-color: #f1f1f1;
    border-radius: 20px;
}

.search .header input[type='search'] {
    width: 90%;
    border-radius: 50px;
    margin-left: 15px;
    margin-left: 15px;
    padding-top: 3px;
    padding-bottom: 3px;
    padding-left: 15px;
    padding-right: 10px;
    outline: none !important;
    border: 1px #808080 solid;
}

.search .header input[type='search']:focus {
    outline: none !important;
    border: 1px solid #1880a1;
    box-shadow: 0 0 2.3px #1880a1;
}

.search .header .search-button {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #1880a1;
    border-radius: 50px;
    padding: 8px;
    margin-right: 15px;
}

.search .tab {
    display: flex;
    justify-content: center;
    align-items: center;
    /* background-color: #1880a1; */
    width: 35%;
    height: 40px;
    cursor: pointer;
}

.search  .containerResult::-webkit-scrollbar {
    width: 10px;
}

.search  .containerResult::-webkit-scrollbar-track {
    background: #f1f1f1;
}

.search  .containerResult::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 10px;
}

.search  .containerResult::-webkit-scrollbar-thumb:hover {
    background: #808080;
}
