/* PHONE */
@media only screen and (max-width: 992px) {
    .loginform {
        display: block;
        width: 100%;
        overflow-y: scroll;
        height: 100vh;
    }
}

/* DESKTOP */
@media only screen and (min-width: 992px) {
    .loginform {
        display: block;
        width: 30%;
    }
    .loginform .form {
        padding-top: 5%;
    }
}

.loginform::-webkit-scrollbar {
    display: none;
}

.loginform {
    display: wrap;
    justify-content: center;
    position: fixed;
    overflow-y: scroll;
    height: 100vh;
}

.loginform .form {
    overflow-y: auto;
    overflow-y: hidden;
    text-align: center;
    margin-left: 20%;
    margin-right: 20%;
    padding-bottom: 25%;
}

.loginform .form .logo-myopinion {
    width: 150px;
    height: 150px;
    margin-left: auto;
    margin-right: auto;
}

.loginform .form .error-message {
    color: red;
    font-size: 13px;
    text-align: center;
    margin-bottom: 5%;
}

.loginform .form .forget-password-text {
    font-size: 13px;
    color: #1880a1;
}

.loginform .form .connect-with {
    font-size: 13px;
    color: gray;
    text-align: center;
    margin-top: 5px;
    margin-bottom: 10px;
}

.loginform .form .social {
    margin-left: -10px;
    width: 99%;
    margin-left: auto;
    margin-right: auto;
}

.loginform .form a {
    color: #222;
}

.loginform .form .select-language {
    font-size: 13px;
    margin-top: 6%;
    margin-bottom: 5%;
    margin-left: auto;
    margin-right: auto;
}

.loginform .form .terms-conditions {
    margin-bottom: 5%;
}

.loginform .form .terms-conditions .policy {
    font-size: 13px;
    font-weight: 500;
    color: #808080;
}

.loginform .form .copyrigth {
    font-size: 14px;
    font-weight: 600;
    color: #808080;
    padding-bottom: 3%;
}