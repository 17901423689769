.agora-details .content::-webkit-scrollbar {
    width: 10px;
}

.agora-details .content::-webkit-scrollbar-track {
    background: #f1f1f1;
    cursor: pointer;
}

.agora-details .content::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 10px;
}

.agora-details .content::-webkit-scrollbar-thumb:hover {
    background: #6e6d6d;
}

.agora-details {
    height: 100vh;
    background-color: white;
}

.agora-details .content {
    height: 83vh;
    overflow-y: scroll;
    background-color: white;
}

.agora-details .header-profile {
    display: flex;
    align-items: center;
    width: 100%;
    height: 50px;
    background-color: white;
    border-bottom: 1px #f1f1f1 solid;
}

.agora-details .header-profile .button-back {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 40px;
    height: 40px;
    background-color: white;
    border-radius: 20px;
    cursor: pointer;
}

.agora-details .header-profile .button-back:hover {
    background-color: #f1f1f1;
    border-radius: 20px;
}

.agora-details .header-profile .title {
    text-align: center;
    width: 85%;
}
