/* PHONE */
@media only screen and (max-width: 992px) {
    .sidebar {
        display: none;
    }

    .outlet {
        width: 100%;
    }

    .endbar {
        display: none;
    }
}


/* DESKTOP */
@media only screen and (min-width: 992px) {
   
    .sidebar {
        display: block;
        width: 25%;
        height: 100vh;
    }

    .outlet {
        width: 50%;
        height: 100vh;
    }

    .endbar {
        display: block;
        width: 25%;
        height: 100vh;
    }
}


.appLayout {
    display: flex;
    width: 100vw;
    height: 100vh;
}

.outlet {
    padding-bottom: 200px;
    background-color: rgb(252, 249, 249);
    border-left: 1px #f1f1f1 solid;
    border-right: 1px #f1f1f1 solid;
}

.sidebar {
    width: 25%;
    height: 100vh;
}

.endbar {
    width: 25%;
    height: 100vh;
}

