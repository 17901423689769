/* HERDER */
.header-componant {
    display: flex;
    align-items: center;
    width: 100%;
    height: 50px;
    background-color: white;
    border-bottom: 1px #f1f1f1 solid;
}

.header-componant .button-back {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 40px;
    height: 40px;
    background-color: white;
    border-radius: 20px;
    cursor: pointer;
    margin-left: 10px;
}

.header-componant .button-back:hover {
    background-color: #f1f1f1;
    border-radius: 20px;
}

.header-componant .title {
    text-align: center;
    width: 80%;
    font-size: 18px;
    font-weight: bold;
    margin-left: 10px;
}
