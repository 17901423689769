.search-badge {
    background-color: white;
    height: 100vh;
}

.search-badge .content {
    padding-bottom: 10px;
    border-bottom: 1px #f1f1f1 solid;
}

.search-badge .content .input-box {
    width: 100%;
    display: flex;
    /* align-items: center; */
    justify-content: space-between;
    padding-left: 5%;
    padding-right: 5%;
    margin-top: 20px;
}

.search-badge .content .input-box .option-button {
    display: flex;
    justify-content: center;
    height: 36px;
    align-items: center;
    background-color: #1880a1;
    border-radius: 50px;
    padding: 8px;
}
