.profile {
    width: 100%;
    height: 100vh;
    overflow: hidden;
}

.profile .infinite-scroll{
    height: 100vh;
}

/* HERDER */
.profile .header-profile {
    display: flex;
    align-items: center;
    width: 100%;
    height: 50px;
    background-color: white;
    border-bottom: 1px #f1f1f1 solid;
}

.profile .header-profile .button-back {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 40px;
    height: 40px;
    background-color: white;
    border-radius: 20px;
    cursor: pointer;
}

.profile .header-profile .button-back:hover {
    background-color: #f1f1f1;
    border-radius: 20px;
}

.profile .header-profile .title {
    text-align: center;
    width: 85%;
}


/* CONTENT */
.profile .content-profile {
    width: 100%;
    border-left: 1px #f1f1f1 solid;
    border-right: 1px #f1f1f1 solid;
}

.profile .content-profile {
    border-left: 1px #f1f1f1 solid;
    border-right: 1px #f1f1f1 solid;
}

.profile .content-profile .cover {
    width: 100%;
    height: 250px;
    object-fit: cover;
    border-bottom: 1px #f1f1f1 solid;
}

.profile .content-profile .picture {
    display: flex;
    margin-left: 50px;
    margin-top: -70px;
}

.profile .content-profile .picture .account {
    margin-top: 80px;
    margin-left: 20px;
}

.profile .content-profile .description {
    margin-top: 20px;
    margin-bottom: 20px;
    padding-left: 20px;
    padding-right: 20px;
}

.profile .content-profile .description .box-info {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
}

.profile .content-profile .button-box {
    display: flex;
    justify-content: space-between;
    padding-left: 15%;
    padding-right: 15%;
    align-items: center;
    margin-top: 20px;
    margin-bottom: 20px;
}

.profile .content-profile .button-box .button {
    display: flex;
    align-items: center;
    justify-content: center;
    /* background-color: #1880a1; */
    /* padding-top: 5px;
    padding-bottom: 5px;
    padding-left: 30px;
    padding-right: 30px; */
    /* border-radius: 4px; */
    /* color: white; */
    text-align: center;
    font-weight: 600;
    font-size: 14px !important;
}

.profile .content-profile .audience {
    margin-right: 30px;
    margin-left: 30px;
    margin-bottom: 20px;
    padding-top: 10px;
    padding-bottom: 15px;
    border-radius: 5px;
    background-color: #f5f5f5;
}