.input {
    font-size: 14px;
    width: 80%;
    height: 35px;
    border: none;
    border-radius: 5px;
    padding: 10px;
    background-color: transparent;
    color: white;
}

.input:focus {
    outline: none !important;
    border: none;  
    box-shadow: none;
}