.container {
    height: 98vh;
    background-color: white;
}

.content {
    width: 100%;
    overflow-y: scroll;
    padding-left: 15%;
    padding-right: 15%
}

.containerLogo {
    width: 100%;
    display: flex;
    justify-content: center;
}
.logo {
    width: 130px;
    height: 130px;
}

.title {
    text-align: center;
    font-weight: 600;
}