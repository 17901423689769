.transactions {
    background-color: white;
    height: 100vh;
    overflow-y: hidden;
}
.transactions .infinite-scroll{
    height: 100vh;
}
.transactions .transactions-content {
    background-color: 'red';
    padding-left: '20px';
    padding-right: '20px';
}