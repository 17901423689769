.screen {
    height: 100vh;
}

.container {
    padding-top: 20px;
    padding-left: 20px;
    padding-right: 20px;
    border-bottom: 1px #f5f5f5 solid;
    background-color: white;
}

.containerButton {
    width: 100%;
    display: flex;
    justify-content: center;
}

.textResult {
    font-size: 15px;
    font-weight: bold;
    color: #1880a1;
    margin-top: 10px;
    text-align: center;
}

.optionContainer {
    display: flex;
    align-items: center;
    margin-bottom: 15px;
}

.optionContainerInput {
    width: 90%;
}

.optionButton {
    display: flex;
    justify-content: center;
    height: 36px;
    align-items: center;
    background-color: #1880a1;
    border-radius: 50px;
    padding: 8px;
}

.contentContainer {
    height: 68vh;
    overflow-y: auto;
    overflow-x: hidden;
    background-color: white;
}