/* Mobile */
@media only screen and (max-width: 1024px) {
    .container {
        width: 100%;
    }

    .remoteContainer{
        width: 100%;
    }

    .localStream {
        width: 100%;
    }

    .localStreamJoined {
        width: 160px;
        height: 160px;
        bottom: 15%;
        right: 4%;
    }
}

/* Desktop */
@media only screen and (min-width: 1024px) {
    .container {
        width: 50%;
    }

    .remoteContainer{
        width: 100%;
    }

    .localStream {
        width: 50%;
    }

    .localStreamJoined {
        padding: 1px;
        width: 200px;
        width: 200px;
        height: 200px;
        bottom: 14%;
        right: 27%;
    }
}