ul {
    list-style-type: none;
}

.user-audience {
    display: flex;
    width: 100%;
    height: 100vh;
    background-color: white;
}

.user-audience .content {
    width: 100%;
    overflow-y: scroll;
    border-left: '1px #f1f1f1 solid';
}

.user-audience .content .topbar {
    display: flex;
    align-items: center;
    position: fixed;
    border: 1px #f1f1f1 solid;
    width: 44.5%;
    height: 50px;
    background-color: white;
    padding-left: 10px;
    
}

.user-audience .content .topbar .button-back {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 40px;
    height: 40px;
    background-color: white;
    border-radius: 20px;
    cursor: pointer;
}

.user-audience .content .topbar .button-back:hover {
    background-color: #f1f1f1;
    border-radius: 20px;
}

.user-audience .content .topbar .title {
    width: 80%;
    font-size: 18px;
    text-align: center;
    margin-left: 15px;
    margin-top: 5px;
    color: '#222'
}


.user-audience .content .content-body {
    margin-top: 50px;
    padding-bottom: 50px;
    border-left: 1px #f1f1f1 solid;
    border-right: 1px #f1f1f1 solid;
}

.user-audience .content .content-body .tablist {
    width: 44.35%;
    display: flex;
    align-items: end;
    position: fixed;
    background-color: white;
    justify-content: space-between;
    height: 35px;
    border-bottom: 1px #f1f1f1 solid;
    padding-right: 30px;
}

.user-audience .content .content-body .tablist .tab {
    font-size: 14px;
    cursor: pointer;
    text-align: center;
    font-weight: 500;
    color: #808080;
    padding-bottom: 5px;
}

.user-audience .content .content-body .tablist .tab-selected {
    font-size: 14px;
    cursor: pointer;
    text-align: center;
    font-weight: 500;
    color: #1880a1;
    padding-bottom: 5px;
    border-bottom: 2px #1880a1 solid;
}

.user-audience .content .content-body .tabpanel {
padding-top: 35px;
}

.button {
    display: flex;
    align-items: center;
    justify-content: center;
    /* background-color: #1880a1; */
    padding-top: 5px;
    padding-bottom: 5px;
    padding-left: 30px;
    padding-right: 30px;
    border-radius: 4px;
    /* color: white; */
    text-align: center;
    font-weight: 600;
    font-size: 14px !important;
}

