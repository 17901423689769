/* Mobile */
@media only screen and (max-width: 1024px) {
    .page {
        width: 100%;
    }

    .container {
        width: 100%;
    }

    .remoteUser{
        width: 99%;
    }
}

/* Desktop */
@media only screen and (min-width: 1024px) {
    .page {
        width: 100%;
    }

    .container {
        width: 100%;
    }

    .remoteUser{
        width: 50%;
    }
}