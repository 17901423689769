/* Mobile */
@media only screen and (max-width: 1024px) {
    .container {
        width: 100%;
    }

    .content {
        background-color: #313131;
    }
}

/* Desktop */
@media only screen and (min-width: 1024px) {
    .container {
        width: 50%;
    }

    .content {
        background-color: #313131;
    }
}

.content {
    background-color: #313131;
}