/* CARD STORE */
.appstore  {
    position: fixed;
    width: 26%;
    text-align: center;
}

.appstore .card-content {
    width: 100%;
    display: flex;
    flex-flow: column wrap;
    text-align: center;
    width: 55%;
    margin-left: auto;
    margin-right: auto;
    padding-top: 8%;
}

.appstore .card-content .logo {
    width: 150px;
    height: 150px;
    margin-left: auto;
    margin-right: auto;
}

.appstore .card-content .img-playstore {
    width: 80%;
    margin-left: auto;
    margin-right: auto;
}

.appstore .card-content .img-appstore {
    width: 80%;
    margin-top: -8px;
    margin-left: auto;
    margin-right: auto;
}

.appstore .card-content .img-appgallery {
    width: 70%;
    margin-left: auto;
    margin-right: auto;
}

.appstore .card-content .select-language {
    font-size: 13px;
    margin-top: 10%;
    margin-bottom: 5%;
    margin-left: auto;
    margin-right: auto;
}

.appstore .card-content .terms-conditions {
    margin-bottom: 5%;
}

.appstore .card-content .copyrigth {
    font-size: 14px;
    font-weight: 600;
    color: #808080;
}

