.people-match {
    margin-top: 10px;
    margin-bottom: 20px;
    padding-left: 10px;
    padding-right: 10px;
    border-bottom: 5px #f1f1f1 solid;
    padding-bottom: 20px;
}

.people-match .title{
    font-size: 16px;
    font-weight: bold;
    margin-left: 10px;
    margin-right: 20px;
    padding-bottom: 5px;
    margin-bottom: 5px;
}