/* PHONE STYLE */
@media only screen and (max-width: 1024px) {
    .container {
        width: 100vw;
        padding-left: 20px;
        padding-right: 20px;
    }

    .contactBox {
        margin-bottom: 20px;
    }
}

/* DESKTOP STYLE */
@media only screen and (min-width: 1024px) {
    .container {
        width: 40vw;
    }

    .contactContainer {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
}

.page {
    display: flex;
    justify-content: center;
    background-color: white;
    overflow-x: hidden;
}

.container {
    padding-top: 20px;
    background-color: white;
    margin-bottom: 100px;
}

.contactContainer {
    margin-top: 20px;
    margin-bottom: 40px;
}

.imageBox {
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
}

.titlePage {
    font-weight: 600;
    text-align: center;
}


.subtitlePage {
    font-size: 15px;
    text-align: center;
    margin-bottom: 20px;
}

.selectLanguageInput {
width: 30%;
margin-left: auto;
}

.contactBox {
    display: flex;
    align-items: center;
}

.iconBox {
    width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 10px;
    border-radius: 40px;
    background-color: var(--color-primary);
}

.formBox {
    border: 1px solid #c0c0c0;
    padding: 20px;
    border-radius: 10px;
}

.titleContact {
    font-size: 16px;
    margin-bottom: 15px;
    font-weight: 600;
    color: var(--color-primary);
}

.label {
    text-align: left !important;
}

.input {
    width: 100%;
    font-size: 14px;
    height: 37px;
    border: 1px gray solid;
    border-radius: 5px;
    padding: 10px;
}

.input:focus {
    outline: none !important;
    border: 1px solid var(--color-primary);
    box-shadow: 0 0 2.3px var(--color-primary);
}


.inputBox {
    margin-bottom: 20px;
}


.textarea {
    width: 100%;
    font-size: 14px;
    border: 1px gray solid;
    border-radius: 5px;
    padding: 10px;
}

.textarea:focus {
    outline: none !important;
    border: 1px solid var(--color-primary);
    box-shadow: 0 0 2.3px var(--color-primary);
}

.button {
    width: 50%;
    padding: 5px;
    border-radius: 3px;
    color: white;
    background-color: var(--color-primary);
 
}