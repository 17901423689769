.conversation {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-right: 20px;
    cursor: pointer;
    border-top: 1px #f1f1f1 solid;
}

.conversation:hover {
    background-color: #f1f1f1;
}

.badge {
    width: 25px;
    color: white;
    border-radius: 5px;
    font-weight: 600;
    font-size: 14px;
    text-align: center;
    padding: 2px;
}