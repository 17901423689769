.container {
    height: 100vh;
    background-color: white;
}

.content {
    height: 92vh;
    overflow-y: auto;
    padding-bottom: 50px;
    background-color: white;
}

.content::-webkit-scrollbar {
    width: 10px;
}

.content::-webkit-scrollbar-track {
    background: #f1f1f1;
}

.content::-webkit-scrollbar-thumb {
    cursor: pointer;
    background: #888;
    border-radius: 10px;
}

.content::-webkit-scrollbar-thumb:hover {
    background: #808080;
}

.header {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 50px;
    border-bottom: 1px #f1f1f1 solid;
    font-weight: 600;
}

.title {
    color: #414141;
    font-weight: bold;
    text-align: center;
}


.agoraContainer {
    display: flex;
    align-items: center;
    cursor: pointer;
    padding: 10px;
    padding-top: 10px;
    padding-bottom: 10px;
}

.agoraContainer:hover {
    background-color: #F1F1F1;
}

.agoraIconContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 50px;
    height: 50px;
    border-radius: 50px;
    background-color: #1880a1;
}

.agoraIcon {
    width: 30px;
    height: 30px;
}

.agoraTitle {
    font-weight: 600;
    font-size: 18px; 
    margin-left: 20px; 
    margin-top: 5px 
}