.onetoone {
    display: flexbox;
    width: 100%;
    background-color: white;
    overflow: hidden;
}

.onetoone .tab {
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: space-around;
    border-bottom: 1px solid #f1f1f1;
}

.onetoone .tablabel {
    padding: 20%;
    color: #808080;
    font-weight: 600;
    cursor: pointer;
    margin: 0px;
}

.onetoone .header {
    height: 53px;
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    padding-left: 15px;
    padding-right: 15px;
    padding-bottom: 10px;
}

.onetoone .header .title {
    font-size: 24px;
    font-weight: bold;
    color: #222;
    margin: 0;
}

input[type='search'] {
    width: 95%;
    height: 42px;
    border-radius: 50px;
    margin-left: 15px;
    /* padding-bottom: 3px; */
    padding-left: 15px;
    padding-right: 10px;
    outline: none !important;
    border: 2px #c0c0c0 solid;
    margin-top: 5px;
    margin-bottom: 10px;
}

input[type='search']:focus {
    outline: none !important;
    border: 1px solid var(--color-primary);
    box-shadow: 0 0 2.3px var(--color-primary);
}
